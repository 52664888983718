import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {Button, Col, Divider, Form, Grid, Loader, Row} from "rsuite";
import { useRealCostsQuery } from "../store/costs-api";
import StatsLabel from "../../statistics/time-aggregation/stats-label";
import {
    clear,
    selectAssignmentIds,
    selectForceUpdate,
    selectRealCostQueryParams,
} from "../store/assignment-slice";
import {
    getLabelCol,
    getAnzahlCol,
    getActionCol,
    getPercentageCol,
    getPriceCol,
    useStyles,
} from "./constants";
import CurrencySelect from "./currency-select";
import SaveButton from "./save-button";
import Assignment from "./assignment";
import LinkButtons from "./link-buttons";
import SioIcon from "../../icon/rsuite-icon-font/SioIcon";
import AureliaContext from "../../utilities/aurelia-context";
import { useUserClient } from "../../react-hooks/useUserClient";
import { useOrganizationQuery } from "../../store/api";

export default function OriginForm({ origin, creditor }) {
    const { currencyValueConverter, percentageValueConverter, i18n } = useContext(AureliaContext);
    const dispatch = useDispatch();
    const params = useSelector(selectRealCostQueryParams);
    const { data, isLoading, isFetching, refetch } = useRealCostsQuery({ origin, ...params });
    const ids = useSelector(selectAssignmentIds);
    const forceUpdate = useSelector(selectForceUpdate);
    const {panel, check} = useStyles()
    const [UserClient] = useUserClient();
    const { isLoading: isLoadingOrganization, data: organization } = useOrganizationQuery(
        UserClient.getUser().organization.id
    );
    const organizationSettings = organization?.settings?.receiptOrganizationSettings ?? {};
    const hideAnzahlCol = organizationSettings.hideCostAssignmentAmount ?? false;
    const [isSplitView, setIsSplitView] = useState(false);
    const { danger } = useStyles()
    const file = data?.file;

    useEffect(() => {
        if (isLoading) {
            dispatch(clear([], ["EUR"]));
        } else if (!isFetching) {
            dispatch(clear(data?.assignments, data?.currencies));
        }
    }, [origin.id, origin.modelId, isFetching]);

    useEffect(() => {
        if (forceUpdate) {
            refetch();
        }
    }, [forceUpdate]);

    if (isLoading || !data || isLoadingOrganization) {
        return <Loader />;
    }

    const assignments = Object.fromEntries(
        (data.assignments ?? []).map(({ id, additionalInfo, destination, calculatedSum, percentage }) => [
            id,
            { additionalInfo, destination, calculatedSum, percentage },
        ])
    );

    return (
        <div className={"panel panel-default " + panel}>
            <Form layout="horizontal" fluid>
                <Grid fluid>
                    <Row>
                        <Col xs={18}>
                            <CurrencySelect/>
                        </Col>
                        <Col xs={24} className="text-right">
                            {file?.previewUrl && (
                                <Button
                                    onClick={() => setIsSplitView(!isSplitView)}
                                    className={danger}
                                >
                                    {isSplitView
                                        ? i18n.tr("costs.close-split-view")
                                        : i18n.tr("costs.split-view")}
                                </Button>
                            )}
                            {data?.assignments?.length && (
                                <SaveButton origin={origin} style={{ marginLeft: "10px" }} />
                            )}
                        </Col>
                    </Row>

                    <Divider/>

                    <div style={{ display: isSplitView ? "flex" : "block" }}>
                        <div style={{ flex: 1 }}>
                            <Row>
                                <Col {...getLabelCol(isSplitView)}>
                                    <strong>
                                        <StatsLabel label={data.origin} />
                                    </strong>
                                </Col>
                                {!hideAnzahlCol && <Col {...getAnzahlCol(isSplitView)}>Anzahl</Col>}
                                <Col {...getPriceCol(hideAnzahlCol, isSplitView)}>
                                    <strong>{currencyValueConverter.toView(data.costs)}</strong>
                                </Col>
                                <Col {...getPercentageCol(hideAnzahlCol, isSplitView)} />
                                <Col {...getPercentageCol(hideAnzahlCol, isSplitView)} />
                                <Col {...getPercentageCol(hideAnzahlCol, isSplitView)}>kalk.</Col>
                                <Col {...getActionCol(isSplitView)} />
                            </Row>

                            <Divider/>

                            {ids.map((id) => (
                                <Assignment
                                    key={id}
                                    id={id}
                                    origin={origin}
                                    isSplitView={isSplitView}
                                    additionalInfo={assignments[id]?.additionalInfo}
                                    calculatedSum={assignments[id]?.calculatedSum}
                                    destination={assignments[id]?.destination}
                                    percentage={assignments[id]?.percentage}
                                    hideAnzahlCol={hideAnzahlCol}
                                />
                            ))}

                            <Row>
                                <Col xs={24}>
                                    <LinkButtons i18n={i18n} origin={origin} creditor={creditor} />
                                </Col>
                            </Row>

                            <Divider />

                            <Row>
                                {(!data.difference || 0 === data.difference.amount) ? (
                                    <>
                                        <Col {...getLabelCol(isSplitView)}></Col>
                                        {!hideAnzahlCol && <Col {...getAnzahlCol(isSplitView)} />}
                                        <Col {...getPriceCol(hideAnzahlCol, isSplitView)}>
                                            <SioIcon icon="fa fa-circle-check" className={check} />
                                        </Col>
                                        <Col {...getPercentageCol(hideAnzahlCol, isSplitView)} />
                                    </>
                                ) : (
                                    <>
                                        <Col {...getLabelCol(isSplitView)}>
                                            <strong>
                                                {data.difference.amount > 0
                                                    ? "noch zuzuweisen"
                                                    : "zu viel zugewiesen"}
                                            </strong>
                                        </Col>
                                        {!hideAnzahlCol && <Col {...getAnzahlCol(isSplitView)} />}
                                        <Col {...getPriceCol(hideAnzahlCol, isSplitView)}>
                                            <strong>{currencyValueConverter.toView(data.difference)}</strong>
                                        </Col>
                                        <Col {...getPercentageCol(hideAnzahlCol, isSplitView)}>
                                            <strong>
                                                {percentageValueConverter.toView(data.differencePercentage)}
                                            </strong>
                                        </Col>
                                    </>
                                )}

                                <Col {...getPercentageCol(hideAnzahlCol, isSplitView)} />

                                <Col {...getPercentageCol(hideAnzahlCol, isSplitView)}>
                                    {data.calculatedSum?.amount && currencyValueConverter.toView(data.calculatedSum)}
                                </Col>

                                <Col {...getActionCol(isSplitView)} />
                            </Row>
                        </div>

                        {isSplitView && file?.previewUrl && (
                            <div style={{ flex: 1, paddingLeft: "20px" }}>
                                <iframe
                                    src={file.previewUrl}
                                    width="100%"
                                    height="600px"
                                    style={{ border: "none" }}
                                    title="PDF Viewer"
                                />
                            </div>
                        )}
                    </div>
                </Grid>

                {isFetching && <Loader backdrop />}
            </Form>
        </div>
    );
}
